import { reset } from 'redux-form';
import { Clipboard } from 'react-native';
import * as referActionsConstants from '../../constants/actions/referActions';
import * as appActionsConstants from '../../constants/actions/appActions';
import * as settingsConstants from '../../constants/actions/settingAction';

import * as refersService from '../../services/refersService';
import * as settingService from '../../services/settingsService';

import { notification } from '../../helperFunctions';
import responseCodes from '../../constants/responseCodes';

export function refer(payloadArg, handleShowHideStrengthForm) {
  return (dispatch) => {
    dispatch({ type: appActionsConstants.SET_SPINNER_VISIBLE });
    refersService
      .refer(payloadArg)
      .then(({ data: { response_code, payload, error } }) => {
        const { errorPayload } = payload;
        dispatch({ type: appActionsConstants.SET_SPINNER_HIDDEN });
        if (response_code === responseCodes.ENTITY_NOT_FOUND) {
          return notification.ref.show({ message: errorPayload, isError: true });
        }
        if (response_code === responseCodes.RECORD_ALREADY_EXISTS && error === 'Referred candidate is already an employ of company'){
          return notification.ref.show({ message: error, isError: true });
        }      
        if (response_code === responseCodes.UPDATE_ERROR || response_code === responseCodes.RECORD_ALREADY_EXISTS) {
          handleShowHideStrengthForm(response_code);
        }
        if (!!payload.refer_request) {
          handleShowHideStrengthForm(
            response_code,
            payloadArg.create_refer_request,
            payloadArg.isSkipped,
            payload.refer_request.id,
            payload.strength_questions_data,
            payload.strength_questions_settings
          );
        } else {
          handleShowHideStrengthForm(
            response_code,
            payloadArg.create_refer_request,
            null,
            null,
            payload.strength_questions_data,
            payload.strength_questions_settings
          );
        }
        dispatch({ type: referActionsConstants.REFER_SUCCESS, payload: payload.linkedin_skills });
      })
      .catch(() => {
        dispatch({ type: appActionsConstants.SET_SPINNER_HIDDEN });
        dispatch({ type: referActionsConstants.REFER_ERROR });
      });
  };
}

export const updateReferral = (body, id, func) => {
  return (dispatch) => {
    dispatch({ type: appActionsConstants.SET_SPINNER_VISIBLE });
    refersService.updateReferral(id, body).then(({ data: { response_code, payload, error } }) => {
      dispatch({ type: appActionsConstants.SET_SPINNER_HIDDEN });
      if (response_code === responseCodes.UPDATE_ERROR) {
        return notification.ref.show({ message: error, isError: true });
      } else {
        func(0, true);
        dispatch({ type: referActionsConstants.REFER_SUCCESS });
      }
    });
  };
};

export const updateAttentionStatus = (body, id) => {
  return (dispatch) => {
    dispatch({ type: appActionsConstants.SET_SPINNER_VISIBLE });
    refersService.updateAttentionStatus(id, body).then(({ data: { response_code, payload, error } }) => {
      dispatch({ type: appActionsConstants.SET_SPINNER_HIDDEN });
      if (response_code === responseCodes.OK) {
        dispatch({ type: referActionsConstants.REFER_SUCCESS });
      } else {
        notification.ref.show({ message: error, isError: true });
      }
    });
  };
};

export function resetForm() {
  return (dispatch) => {
    dispatch(reset('referForm'));
  };
}

export function referContinue() {
  return (dispatch) => {
    dispatch(reset('referForm'));
    dispatch({ type: referActionsConstants.REFER_AGAIN });
  };
}

export function copyReferralLink(data) {
  return (dispatch) => {
    refersService
      .copyReferralLink(data)
      .then((response) => {
        Clipboard.setString(response.data.payload.referral_link);
        notification.ref.show({ message: 'Referral link copied to clipboard' });
      })
      .catch((e) => {
        notification.ref.show({ message: e.data.error });
      });
  };
}

export function getDefaultSettings(orgId) {
  return (dispatch) => {
    settingService
      .getDefaultValues(orgId)
      .then((response) => {
        dispatch({
          payload: response.data.payload.default_values,
          type: settingsConstants.SET_DEFAULT_SETTINGS,
        });
      })
      .catch((e) => {
        notification.ref.show({ message: e.data.error });
      });
  };
}
