import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  rootCont: {
    display: 'flex',
    flexDirection: 'column',
  },
  loading: {
    borderWidth: 0.5,
    borderColor: '#c2c2c2',
    borderStyle: 'solid',
    borderRadius: 4,
    marginTop: 10,
    padding: 5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    width: '100%',
    backgroundColor: '#ffffff',
  },
  loadingText: {
    fontSize: 15,
    color: 'rgb(153, 153, 153)',
  },
  suggestionsCont: {
    borderWidth: 1,
    borderColor: '#c2c2c2',
    borderStyle: 'solid',
    borderRadius: 4,
    marginTop: '30px',
    width: '100%',
    backgroundColor: '#ffffff',
    zIndex: 10,
  },
  suggestionItem: {
    cursor: 'pointer',
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor: '#ffffff',
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    borderRadius: 4,
  },
  suggestionItemActive: {
    backgroundColor: 'rgb(222, 235, 255)',
    cursor: 'pointer',
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 10,
    paddingRight: 10,
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
  },
  dropDownButton: {
    paddingVertical: 4,
    paddingHorizontal: 4,
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 6,
    backgroundColor: '#fff',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 140,
    background: '#fff',
    border: "1px solid #ccc",
    padding: '8px 12px',
    cursor: 'pointer',
    fontSize: '14px'
  },
  innerDropdownItem: {
    position: 'absolute',
    top: 45,
    right: 0,
    width: 140,
    backgroundColor: '#fff',
    borderRadius: 5,
    borderWidth: 1,
    borderColor: '#ccc',
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.2,
    shadowRadius: 5,
    elevation: 3,
    zIndex: 9999,
  },
  radiusDropdownMainContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 12,
    zIndex: 2,
    margin: 6,
  }
});
